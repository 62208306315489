import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import SEO               from '@interness/web-core/src/components/modules/SEO/SEO';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Display           from '@interness/theme-twentytwenty/src/components/Display/Display';
import Boxgroup          from '@interness/theme-twentynineteen/src/components/Boxgroup/Boxgroup';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';
import CallToAction      from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';

function IndexPage({ data }) {

  const displayInserts = [
    {
      title: 'Schmuck',
      description: 'Wir fertigen Damen- und Herrenschmuck in Handarbeit nach eigenen Entwürfen.',
      image: data.displayInserts.images[1].file.localFile.childImageSharp.fluid,
      link: '/schmuck'
    },
    {
      title: 'Galerie',
      description: 'Sehen Sie sich unsere Schmuckstücke an.',
      image: data.colliers.images[3].file.localFile.childImageSharp.fluid,
      link: '/galerie'
    },
    {
      title: 'Service',
      description: 'Umarbeitung von Schmuck und moderne Lasertechnik. Der Einsatz von Lasertechnik ermöglicht es auf kleinstem Raum zu arbeiten. Der gebündelte Laserstrahl verhindert unnötiges Erhitzen des gesamten Materials.',
      image: data.displayInserts.images[0].file.localFile.childImageSharp.fluid,
      link: '/service'
    },
    {
      title: 'Trauringe',
      description: 'Ein Trauring ist Symbol für die Liebe und soll auch im Alltag die Erinnerung an den schönsten Moment im Leben immer wieder neu erstrahlen lassen.',
      image: data.gerstner.brand.gallery_images_square[0].file.localFile.childImageSharp.fluid,
      link: '/trauringe/gerstner'
    },
  ]

  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data.headerImages.images.map((image) => (
          <Img key={image.file.localFile.name}
               fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Mounted>
        <Boxgroup floating={false}/>
        <Spacer/>
      </Mounted>
      <Wrapper width={1024}>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Goldschmiede Gräfin Strachwitz</Heading>
          <Spacer/>
          <span style={{ textAlign: 'justify' }}>
           <p>Herzlich Willkommen</p>
          <p>Wir würden Sie gerne in der Goldschmiede Gräfin Strachwitz in Hemer begrüßen,
          der Felsenmeerstadt im Märkischen Kreis.</p>
          <p>Als Goldschmiede stehen wir für Ideen und Kreativität in der Goldschmiedekunst.
          In Handarbeit fertigen wir nach eigenen Entwürfen oder nach Ihren eigenen Vorstellungen Damenschmuck und Herrenschmuck an.
          Leidenschaft und fortwährendes Bestreben gehört genauso zu unserem Service, sowie kompetente und persönliche Beratung.</p>
          <p>Individuelle Anfertigungen bei Eheringen und Schmuck ist für das Team in der Goldschmiede Gräfin Strachwitz selbstverständlich .
          Kreativität bietet unser Team, in der Meisterwerkstatt.</p>
          <p>Bei einer angenehmen Beratung laden wir Sie herzlich ein , Ihren Lieblingsschmuck auszuwählen oder anzuprobieren.
          Zusätzlich nehmen wir uns Ihren Anfragen über Uhren gerne an, da wir eng mit unserem Hauptgeschäft
          <Link external to="https://juwelierhohage.de">Juwelier Hohage in Lüdenscheid</Link> zusammen arbeiten.</p>
          <p>Wir freuen uns auf Ihren Besuch in unserem Geschäft, in der Steinstraße 4 in Hemer.
          In entspannter Atmosphäre nehmen wir uns ausgiebig Zeit für Ihre Wünsche.</p>
          <p>Goldschmiede Gräfin Strachwitz <br/>
            in Hemer</p>
      </span>
        </section>
        <Spacer/>
        <section>
          <Heading tag="h2">Die Vielfalt von Goldschmiede Gräfin Strachwitz</Heading>
          <Display exclude={['wedding_rings']} inserts={displayInserts}/>
        </section>
        <Spacer/>
      </Wrapper>
      <CallToAction/>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        colliers: directusMediaCollection(name: {eq: "galerie-colliers"}) {
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        gerstner: directusBrands(brand: {slug: {eq: "gerstner"}}) {
            brand {
                gallery_images_square {
                    file {
                        width
                        height
                        localFile {
                            name
                            childImageSharp {
                                fluid(maxWidth: 700, cropFocus: CENTER) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        displayInserts: directusMediaCollection(name: {eq: "display_inserts"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;